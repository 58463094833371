import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GithubImage } from "./GithubImage";
import { Link } from "@material-ui/core";
import styled from "styled-components";

const StyledLink = styled(Link)`
  background-color: #333;

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GithubAccount:FunctionComponent = () =>{
  
  const data = useStaticQuery(
    graphql`
      query SiteMetadataForGithubAccount{
        site {
          siteMetadata {
            github
          }
        }
      }
    `
  );

  return (
    <StyledLink href={data.site.siteMetadata.github} >
      <GithubImage />
    </StyledLink>
  )
}