import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";

type Property = {
  html: string;
}

export const Iframely: FunctionComponent<Property> = (props) =>(
  <Box my={2}>
    <div dangerouslySetInnerHTML={{__html: props.html}} />
  </Box>
);