import React, { FunctionComponent, useEffect } from "react";
import { Helmet } from "react-helmet";

export const IframelySetup: FunctionComponent = () =>{

  useEffect(() =>{
    const iframely = (window as any).iframely;
    if(iframely){
      iframely.load();
    }
  });

  return (
    <Helmet>
      <script type="text/javascript" src="https://cdn.iframe.ly/embed.js" />
    </Helmet>
  );
}