import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export const GithubImage = () =>{
  const data = useStaticQuery(
    graphql`
      query GithubImage{
        imageFile: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "social/GitHub-Mark-Light-64px.png"}) {
          childImageSharp {
            fixed(width: 32, height: 32) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  return (
    <Img fixed={data.imageFile.childImageSharp.fixed} />
  );
}