import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@material-ui/core";
import { TwitterImage } from "./TwitterImage";

export const TwitterAccount: FunctionComponent = () =>{
  
  const data = useStaticQuery(
    graphql`
      query SiteMetadataForTwitterAccount{
        site {
          siteMetadata {
            twitter
          }
        }
      }
    `
  );

  return (
    <Link href={data.site.siteMetadata.twitter} >
      <TwitterImage />
    </Link>
  )
}