import React, { FunctionComponent } from "react";
import { Layout } from "../templates/Layout";
import { Box, Grid } from "@material-ui/core";
import { IframelySetup } from "../components/helmets/IframelySetup";
import { Iframely } from "../components/embed/Iframely";
import { LogoImage } from "../components/index/LogoImage";
import { Pharagraph } from "../components/common/Pharagraph";
import { TwitterAccount } from "../components/social/TwitterAccount";
import { GithubAccount } from "../components/social/GithubAccount";
import { Seo } from "../components/helmets/Seo";

const Index: FunctionComponent = () =>{

  return(
    <Layout>
      <Seo title="Home" />
      <IframelySetup />
      <Box my={2}>
        <LogoImage />
      </Box>
      <Pharagraph>
        はっさくみんとの製作物や私的な日記や備忘録を置くところ
      </Pharagraph>
      <Pharagraph>
        ご相談などがありましたらTwitterから適当にご連絡くださいまし
      </Pharagraph>

      <Box my={4}>
        <Pharagraph>
          ソーシャルアカウント
        </Pharagraph>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <TwitterAccount />
          </Grid>
          <Grid item>
            <GithubAccount />
          </Grid>
        </Grid>
      </Box>

      <Box my={4}>
        <Pharagraph>
          Skeb
        </Pharagraph>
        <Iframely html={`
        <div class="iframely-embed"><div class="iframely-responsive" style="height: 140px; padding-bottom: 0;"><a href="https://skeb.jp/@HassakuTb" data-iframely-url="//cdn.iframe.ly/KmuU2qM?iframe=card-small"></a></div></div>
        `} />
      </Box>
      
    </Layout>
  );
}

export default Index;