import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledImg = styled(Img)`
  max-width: 300px;
`;

export const LogoImage: FunctionComponent = () =>{
  const data = useStaticQuery(
    graphql`
      query LandingLogoImage{
        imageFile: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "logo_t.png"}) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <StyledImg fluid={data.imageFile.childImageSharp.fluid} />
  );
}